import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Navbar() {

    const navigate = useNavigate();
    const { bardis } = useAuth();
    const location = useLocation();
    const [ishome, setIshome] = useState(true);
    const [searchval, setSearchval] = useState('');

    useEffect(() => {
        setIshome(location.pathname === '/');
    }, [location.pathname]);

    const [navdis, setNavdis] = useState('none');

    const navsearchFunc = () => {
        localStorage.setItem('localsearch', searchval);
        navigate('/explore');
    }

  return (
    <div className='nav-wraper'>
        <div className={`main-navbar ${bardis? 'd-none': 'd-block'}`}>
            <div className="container-fluid d-flex justify-content-between align-items-center py-3">
                <div className="logo">
                    {/* <h2 className="fs-2 body-font-extrabold">Shah</h2> */}
                    <div className="nav-img-wrap p-3 rounded-brand bg-white">
                        <Link to='/' className='nav-link'><img src="/assets/logo.webp" alt="" className="img-fluid" /></Link>
                    </div>
                </div>
                <div className="ul-wrap desk-ul d-flex align-items-center">
                    <ul className="d-flex p-0 mb-0">
                        <li className="myli mb-0 p-0">
                            <Link to="/" className='nav-link'><p className="mb-0 me-5 text-white body-font-semibold">Home</p></Link>
                        </li>
                        <li className="myli mb-0 p-0">
                            <Link to="/product" className='nav-link'><p className="mb-0 me-5 text-white body-font-semibold">Products</p></Link>
                        </li>
                        <li className="myli mb-0 p-0">
                            <Link to="/product/men-watch" className='nav-link'><p className="mb-0 me-5 text-white body-font-semibold">Men</p></Link>
                        </li>
                        <li className="myli mb-0 p-0">
                            <Link to="/product/women-watch" className='nav-link'><p className="mb-0 me-5 text-white body-font-semibold">Women</p></Link>
                        </li>
                        <li className="myli mb-0 p-0">
                            <Link to="/product/celebrity-watch" className='nav-link'><p className="mb-0 me-0 text-white body-font-semibold">Celebrity</p></Link>
                        </li>
                    </ul>
                </div>
                <div className="profile-wraper desk-profile d-flex align-items-center">
                    {
                        window.location.pathname !== '/explore' && (
                            <div className="nav-search d-flex align-items-center border border-brand-light-brown rounded-pill bg-white px-3 py-2 me-2">
                                <input type="text" className="form-control p-0 shadow-none outline-none me-2 border-0" onChange={(e) => setSearchval(e.target.value)} value={searchval} placeholder='Search' onKeyDown={(e) => e.key === 'Enter' && navsearchFunc()}/>
                                <i className="fas fa-search cursor-pointer text-brand-light-brown" onClick={navsearchFunc}></i>
                            </div>
                        )
                    }
                    {/* {
                        isloggedin? (
                            <Link to="/profile" className='nav-link'>
                                <p className="mb-0 body-font-bold btn btn-outline-dark nav-profile"><i className="fas fa-user"></i></p>
                            </Link>
                        ):(
                            <div className="d-flex">
                                <Link to="/login" className='nav-link w-max-content px-3 rounded-pill border border-brand-light-brown text-brand-light-brown nav-profile me-2'>Log in</Link>
                                <Link to="/signup" className='nav-link w-max-content px-3 rounded-pill bg-brand-brown text-white nav-profile'>Sign up</Link>
                            </div>
                        )
                    } */}
                </div>
                <div className="mob-nav-call-waht-wrap align-items-center justify-content-between w-75 ms-5">
                    <a href='tel:+919082874084' className="mb-0 nav-link"><i className="fas fa-phone-alt text-white fs-4"></i></a>
                    <a href='https://wa.me/+919082874084' className="mb-0 nav-link"><i className="fab fa-whatsapp text-white fs-2"></i></a>
                    <div className="mob-nav-icon d-flex flex-column align-items-end cursor-pointer" onClick={()=> setNavdis(navdis === 'none'? 'flex': 'none')}>
                        <div className={`nav-ham bg-white ${navdis === 'flex'? 'cross-class': ''} mb-2`}></div>
                        <div className={`nav-ham bg-white ${navdis === 'flex'? 'cross-class': ''}`}></div>
                    </div>
                </div>
                <div className="mob-ul" style={{display: navdis}}>
                    <ul className="m-0 p-3">
                        <Link to='/' className='nav-link mb-4'><img src="/assets/logo.webp" width={200} alt="" className="img-fluid" /></Link>
                        <li className="mob-li mb-4">
                            {
                                window.location.pathname !== '/explore' && (
                                    <div className="d-flex justify-content-between align-items-center border border-dark w-100 overflow-hidden px-3 py-2 bg-white rounded-pill">
                                        <input type="text" className='form-control shadow-none outline-none border-0 p-0 m-0' onChange={(e) => setSearchval(e.target.value)} value={searchval} placeholder='Search' onKeyDown={(e) => {e.key === 'Enter' && navsearchFunc(); setNavdis('none')}}/>
                                        <i className="fas fa-search" onClick={navsearchFunc}></i>
                                    </div>
                                )
                            }
                        </li>
                        <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                            <Link to="/" className="mb-2 fs-5 nav-link">Home</Link>
                        </li>
                        <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                            <Link to="/product" className="mb-2 fs-5 nav-link">Products</Link>
                        </li>
                        <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                            <Link to="/product/men-watch" className="mb-2 fs-5 nav-link">Men</Link>
                        </li>
                        <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                            <Link to="/product/women-watch" className="mb-2 fs-5 nav-link">Women</Link>
                        </li>
                        <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                            <Link to="/product/celebrity-watch" className="mb-2 fs-5 nav-link">Celebrity</Link>
                        </li>
                        {/* <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                            <Link to="/cart" className="mb-2 fs-5 nav-link">Cart</Link>
                        </li>
                        <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                            <Link to="/orders" className="mb-2 fs-5 nav-link">Orders</Link>
                        </li>
                        <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                            <Link to="/history" className="mb-2 fs-5 nav-link">History</Link>
                        </li> */}
                        {/* {
                            isloggedin? (
                                <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                                    <Link to="/profile" className="mb-2 fs-5 nav-link">Profile</Link>
                                </li>
                            ): (
                                <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                                    <Link to="/signup" className="mb-2 fs-5 nav-link">Sign up</Link>
                                    <Link to="/login" className="mb-2 fs-5 nav-link">Log in</Link>
                                </li>
                            )
                        } */}
                    </ul>
                </div>
            </div>
        </div>
        {
            ishome? (
                <div className="mob-nav-search search-bar p-3 pt-0">
                    <div className="d-flex align-items-center justify-content-between bg-white w-100 rounded-pill p-3 py-2">
                        <input type="text" className="form-control border-0 shadow-none outline-none p-0" onChange={(e) => setSearchval(e.target.value)} value={searchval} placeholder='Search...' onKeyDown={(e) => e.key === 'Enter' && navsearchFunc()}/>
                        <p className="mb-0 text-brand-green" onClick={navsearchFunc}><i className="fas fa-search ms-3 fs-5"></i></p>
                        {/* <p className="mb-0 text-brand-green"><i className="fi fi-rr-settings-sliders ms-3 fs-5"></i></p> */}
                    </div>
                </div>
            ): (
                ''
            )
        }
    </div>
  )
}

export default Navbar