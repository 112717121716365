import React, { useEffect, useState } from 'react';
import './Listing.css';
import { Link, useParams } from 'react-router-dom';
import Procard from '../../Components/Procard/Procard';

function Listing() {

    const { cate } = useParams();
    const { brand } = useParams();

    const [cat, setCat] = useState('');
    const [bran, setBran] = useState('');
    const [pircefilter, setPricefilter] = useState(0);
    const [filterpro, setFilterpro] = useState([]);
    const [brandfilter, setBrandfilter] = useState([]);
    const [branddrop, setBranddrop] = useState('none');
    const [brandval, setBrandval] = useState('Brand');
    const [loaderdis, setLoaderdis] = useState('none');

    useEffect(() => window.scrollTo(0, 0), []);

    useEffect(() => {
        setLoaderdis('flex');
        if(filterpro &&  filterpro?.length > 0){
            setLoaderdis('none');
        }else{
            setTimeout(() => {
                setLoaderdis("none")
            }, 5000);
        }
    }, [filterpro])

    useEffect(() => {
        setLoaderdis('flex');
        if(brandfilter &&  brandfilter?.length > 0){
            setLoaderdis('none');
        }else{
            setTimeout(() => {
                setLoaderdis("none")
            }, 5000);
        }
    }, [brandfilter])

    useEffect(() => {
        setCat(cate?.replace(/-/g, ' ').replace(/_/g, '-'));
        setBran(brand?.replace(/-/g, ' ').replace(/_/g, '-'));
        setBrandval(brand?.replace(/-/g, ' ').replace(/_/g, '-'));
    }, [cate, brand]);

    const [allpro, setAllpro] = useState([]);
    const [temppro, setTemppro] = useState([]);

    useEffect(() => {
        fetch(`https://shahstore.co.in/api/`).then(res => res.json()).then(data => {
            setLoaderdis('flex');
            if (data.success) {
                setTemppro(data.pro);
                setAllpro(data.pro);
                setFilterpro(data.pro);
                setLoaderdis('none');
            } else {
                console.log(data);
            }
        }).catch(err => console.log('error occured while getting all the pros, ', err));
    }, []);

    useEffect(() => {
        if (cat && cat.length > 0) {
            const filteredByCategory = temppro.filter(e => e.category.toLowerCase() === cat.toLowerCase());
            setAllpro(filteredByCategory);
            if (bran && bran.length > 0) {
                // const filteredByBrand = filteredByCategory.filter(e => e.brand.toLowerCase() === bran.toLowerCase());
                const filteredByBrand = [];
                filteredByCategory.forEach(pro => {
                    if(pro.brand.toLowerCase() === bran.toLowerCase()){
                        filteredByBrand.push(pro);
                    }
                })
                setFilterpro(filteredByBrand);
                setBrandfilter(filteredByBrand);
            } else {
                setFilterpro(filteredByCategory);
            }
        } else {
            setAllpro(temppro);
            setFilterpro(temppro);
        }
    }, [cat, bran, temppro]);

    useEffect(() => {
        if (pircefilter === 1) {
            const ascendingPros = [...allpro].sort((a, b) => a.discountprice - b.discountprice);
            setFilterpro(ascendingPros);
        }
        else if (pircefilter === 2) {
            const descendingPros = [...allpro].sort((a, b) => b.discountprice - a.discountprice);
            setFilterpro(descendingPros);
        }
        else if (pircefilter === 0) {
            const latest = [...allpro].reverse();
            setFilterpro(latest);
        }
    }, [allpro, pircefilter]);

    useEffect(() => {
        if(bran){
            if (pircefilter === 1) {
                const ascendingPros = [...brandfilter].sort((a, b) => a.discountprice - b.discountprice);
                setFilterpro(ascendingPros);
            }
            else if (pircefilter === 2) {
                const descendingPros = [...brandfilter].sort((a, b) => b.discountprice - a.discountprice);
                setFilterpro(descendingPros);
            }
            else if (pircefilter === 0) {
                const latest = [...brandfilter].reverse();
                setFilterpro(latest);
            }
        }
    }, [pircefilter, brandfilter, bran]);

    const mensbrand = ["Audemars Piguet", "Bell & Ross", "Breitling", "Bvlgari", "Cartier", "Franck Muller", "Gucci", "Hublot", "IWC (Schaffhausen)", "Jacob & Co.", "Jaeger Le-coultre (JLC)", "Luminor Panerai", "Omega", "Patek Philippe", "Richard Mille", "Roger Dubuis", "Rolex", "Seiko", "Seven Friday", "Tag Heuer", "Tissot", "Tudor", "U-boat", "Ulysse Nardin", "Armani Exchange (OG)", "Hugo boss(OG)", "Guess(OG)", "Maserati (og)"];
    

    return (
        <div className='main-lising'>
            <div className="container-fluid bg-brand-light0 pb-2 mt-4">
                <div className="heading-wrap d-flex align-items-center justify-content-between pt-4 pb-2">
                    <h2 className="heading-text fs-4 body-font-bold mb-0">Shop</h2>
                    <p className="mb-0 page-indicator text-muted body-font-bold lh-sm">Home {'>'} Product {window.location.pathname !== '/product' && '>'} {cate}</p>
                </div>
                <div className="listing-tabs mob-filter mx-auto rounded-brand-lg pt-4">
                    <p className={`mb-3 pb-1 w-max-content me-3 text-uppercase rounded-brand-sm border border-white px-3 py-2 bg-white text-nowrap ${window.location.pathname === "/product" ? "bg-brand-light-brown text-white border-0" : ""}`}><Link to="/product" className='nav-link body-font-semibold'>All</Link></p>
                    <p className={`mb-3 pb-1 w-max-content me-3 text-uppercase rounded-brand-sm border border-white px-3 py-2 bg-white text-nowrap ${cat === "men watch" ? "bg-brand-light-brown text-white border-0" : ""}`}><Link to='/product/men-watch' className='nav-link body-font-semibold'>Men Watch</Link></p>
                    <p className={`mb-3 pb-1 w-max-content me-3 text-uppercase rounded-brand-sm border border-white px-3 py-2 bg-white text-nowrap ${cat === "women watch" ? "bg-brand-light-brown text-white border-0" : ""}`}><Link to='/product/women-watch' className='nav-link body-font-semibold'>women Watch</Link></p>
                    <p className={`mb-3 pb-1 w-max-content me-3 text-uppercase rounded-brand-sm border border-white px-3 py-2 bg-white text-nowrap ${cat === "celebrity watch" ? "bg-brand-light-brown text-white border-0" : ""}`}><Link to='/product/celebrity-watch' className='nav-link body-font-semibold'>Celebrity Watch</Link></p>
                    <p className={`mb-3 pb-1 w-max-content me-3 text-uppercase rounded-brand-sm border border-white px-3 py-2 bg-white text-nowrap ${cat === "swizz eta watch" ? "bg-brand-light-brown text-white border-0" : ""}`}><Link to='/product/swizz-eta-watch' className='nav-link body-font-semibold'>Swizz ETA Watch</Link></p>
                </div>
            </div>
            <div className="row p-0 m-0 pb-5">
                <div className="col-md-3 mt-4 pt-1">
                    <div className="listing-tabs desk-filter flex-column flex-wrap justify-content-start mx-auto border rounded-brand-lg p-3 py-4">
                        <p className="fs-4 body-font-bold border-bottom text-center pb-3 mb-3">Product Category</p>
                        <p className={`mb-3 pb-1 w-max-content text-uppercase ${window.location.pathname === "/product" ? "border-bottom border-dark border-2" : ""}`}><Link to="/product" className='nav-link'>All</Link></p>
                        <p className={`mb-3 pb-1 w-max-content text-uppercase ${cat === "men watch" ? "border-bottom border-dark border-2" : ""}`}><Link to='/product/men-watch' className='nav-link'>Men Watch</Link></p>
                        <p className={`mb-3 pb-1 w-max-content text-uppercase ${cat === "women watch" ? "border-bottom border-dark border-2" : ""}`}><Link to='/product/women-watch' className='nav-link'>women Watch</Link></p>
                        <p className={`mb-3 pb-1 w-max-content text-uppercase ${cat === "celebrity watch" ? "border-bottom border-dark border-2" : ""}`}><Link to='/product/celebrity-watch' className='nav-link'>Celebrity Watch</Link></p>
                        <p className={`mb-3 pb-1 w-max-content text-uppercase ${cat === "swizz eta watch" ? "border-bottom border-dark border-2" : ""}`}><Link to='/product/swizz-eta-watch' className='nav-link'>Swizz ETA Watch</Link></p>
                    </div>
                </div>
                <div className="col-md-9 pt-0">
                    <div className="product-n-filter mt-4">
                        <div className="price-filter-wrap d-flex justify-content-between align-items-center flex-wrap">
                            <div className="price-filter d-flex align-items-center">
                                <p className={`px-3 py-2 border text-capitalize fs-sm rounded-brand-sm me-2 mb-2 cursor-pointer body-font-semibold ${pircefilter === 0 && "bg-brand-light-brown text-white border-0"}`} onClick={() => setPricefilter(0)}>latest</p>
                                <p className={`px-3 py-2 border text-capitalize fs-sm rounded-brand-sm me-2 mb-2 cursor-pointer body-font-semibold ${pircefilter === 1 && "bg-brand-light-brown text-white border-0"}`} onClick={() => setPricefilter(1)}>lowest - highest</p>
                                <p className={`px-3 py-2 border text-capitalize fs-sm rounded-brand-sm me-2 mb-2 cursor-pointer body-font-semibold ${pircefilter === 2 && "bg-brand-light-brown text-white border-0"}`} onClick={() => setPricefilter(2)}>highest - lowest</p>
                            </div>
                            {
                                cate === 'men-watch' && (
                                    <div className="brand-dropdown-wrap">
                                        <p className="m-0 px-3 py-2 border rounded-brand-sm cursor-pointer text-capitalize" onClick={() => setBranddrop(branddrop === 'none'? 'block': 'none' )}>{brandval} <i className="fas fa-caret-down text-muted ms-2"></i></p>
                                        <div className="brand-dropdown-options pt-2 bg-white" style={{display: branddrop}}>
                                            {
                                                mensbrand.map((pro, ind) =>(
                                                    <Link key={ind} to={`/product/men-watch/${pro.toLowerCase().replace(/-/g, '_').replace(/ /g, '-')}`} className="nav-link mb-1 border text-capitalize px-2" onClick={(e) => {setBranddrop('none'); setBrandval(e.target.innerText)}}>{pro}</Link>
                                                ))
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="main-listing">
                            <div className="row">
                            {
                                bran ? (
                                    filterpro.map((pro, ind) => (
                                        <div key={ind} className="col-md-3 col-6 p-2 mb-3">
                                            <Procard id={pro.proid} img={pro.images[0]} tag={pro.status} name={pro.brand} 
                                                    discountprice={pro.discountprice} desc={pro.name} price={pro.price} 
                                                    category={pro.category} star={pro.stars} reviewcount={pro.noreviews} instock={pro.instock} cate={pro.category} />
                                        </div>
                                    ))
                                ):(
                                    filterpro?.length > 0 && filterpro.map((pro, ind) => (
                                        <div key={ind} className="col-md-3 col-6 p-2 mb-3">
                                            <Procard id={pro.proid} img={pro.images[0]} tag={pro.status} name={pro.brand} 
                                                    discountprice={pro.discountprice} desc={pro.name} price={pro.price} 
                                                    category={pro.category} star={pro.stars} reviewcount={pro.noreviews} instock={pro.instock} cate={pro.category} />
                                        </div>
                                    ))
                                )
                            }
                            {
                                filterpro?.length < 1 && (
                                    <p className="my-5 body-font-bold text-center fs-5">No product found!</p>
                                )
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid pb-5 d-none">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="shipping-items d-flex align-items-center rounded-brand bg-brand-light2 p-3">
                            <div className="shippig-card-icon bg-brand-light-brown rounded-circle d-flex justify-content-center align-items-center me-3">
                                <i className="fas fa-truck text-white"></i>
                            </div>
                            <div className="shipping-card-text">
                                <p className="mb-0 fs-5 body-font-bold">Free Shipping</p>
                                <p className="mb-0 text-muted lh-sm">Free shipping all over the India</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="shipping-items d-flex align-items-center rounded-brand bg-brand-light2 p-3">
                            <div className="shippig-card-icon bg-brand-light-brown rounded-circle d-flex justify-content-center align-items-center me-3">
                                <i className="fas fa-hand-holding-heart text-white"></i>
                            </div>
                            <div className="shipping-card-text">
                                <p className="mb-0 fs-5 body-font-bold">100% Satisfaction</p>
                                <p className="mb-0 text-muted lh-sm">Free shipping all over the India</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="shipping-items d-flex align-items-center rounded-brand bg-brand-light2 p-3">
                            <div className="shippig-card-icon bg-brand-light-brown rounded-circle d-flex justify-content-center align-items-center me-3">
                                <i className="fas fa-credit-card text-white"></i>
                            </div>
                            <div className="shipping-card-text">
                                <p className="mb-0 fs-5 body-font-bold">Secure Payment</p>
                                <p className="mb-0 text-muted lh-sm">Free shipping all over the India</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="shipping-items d-flex align-items-center rounded-brand bg-brand-light2 p-3">
                            <div className="shippig-card-icon bg-brand-light-brown rounded-circle d-flex justify-content-center align-items-center me-3">
                                <i className="fas fa-comments text-white"></i>
                            </div>
                            <div className="shipping-card-text">
                                <p className="mb-0 fs-5 body-font-bold">24/7 Support</p>
                                <p className="mb-0 text-muted lh-sm">Free shipping all over the India</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="loader-wrpper" style={{display: loaderdis}}>
                <div className="loader-wrap">
                    <img src="/assets/loader-logo.webp" alt="loader logo" className="img-fluid" />
                </div>
            </div>

        </div>
    )
}

export default Listing