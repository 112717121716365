import React, { useEffect, useState } from 'react';
import './Procard.css';
import { Link } from 'react-router-dom';

function Procard(props) {

    const [loaded, setLoaded] = useState(false);

    const nameFormatter = (txt) => {
        if(window.innerWidth < 800){
            return txt?.length > 15? txt.slice(0, 15)+'...': txt;
        }else{
            return txt?.length > 50? txt.slice(0, 50)+'...': txt;
        }
    } 
    const descFormatter = (txt) => {
        if(window.innerWidth < 800){
            return txt?.length > 53? txt.slice(0, 53)+'...': txt;
        }else{
            return txt?.length > 90? txt.slice(0, 90)+'...': txt;
        }
    } 

    useEffect(() => {
        const img = new Image();
        img.src = props?.img;
        img.onload = () => setLoaded(true);
    }, [props?.img]);
    

  return (
    <div className='main-procard'>
        <div className="procard bg-brand-light">
            <Link to={`/${props?.id}/${encodeURIComponent(props?.desc)}`} className='nav-link'>
                <div className="p-2">
                    {
                        loaded ? (
                            <div className="procard-img text-center overflow-hidden p-2" style={{backgroundImage: `url('${props?.img}')`}}>
                                {/* <img src={props?.img} alt={props?.name} className="img-fluid rounded-brand" /> */}
                                <div className="water-wrap">
                                    <img src="/assets/watermark.webp" alt="watermark" className="img-fluid" />
                                </div>
                            </div>
                        ): (
                            <div className="shimmer-effect"></div>
                        )
                    }
                </div>
            </Link>
            <div className="procard-details p-2">
                <Link to={`/${props?.id}/${encodeURIComponent(props?.desc)}`} className='nav-link'><p className="procard-name mb-0 body-font-extrabold text-uppercase text-center fs-esm text-brand-light-brown">{nameFormatter(props?.name)}</p></Link>
                <p className="procard-desc mb-0 body-font-bold text-center text-capitalize lh-sm fs-esm">{descFormatter(props?.desc)}</p>
                <div className="price-top-line rounded-pill mt-2"></div>
                {
                    props.cate === 'Swizz Eta Watch' ? (
                        <p className="text-center mb-0 fs-5 body-font-bold mt-2 mx-auto w-max-content"><span className="text-brand-light-brown body-font-extrabold">SEE </span>PRICE</p>
                    ):(
                        <p className="text-center mb-0 fs-3 body-font-bold mt-2 mx-auto w-max-content"><span className="text-brand-light-brown body-font-extrabold">₹</span>{props?.discountprice}</p>
                    )
                }
                <div className="addtocart-btn-wrap mt-2 w-100 text-center">
                    {
                        props.cate === 'Swizz Eta Watch'? (
                            <a href='https://wa.me/+919082874084' target='_blank' rel='noreferrer' className="addtocart-btn btn w-max-content bg-brand-light2 mx-auto text-white rounded-pill body-font-semibold px-3 text-brand-dark">DM FOR PRICE</a>
                        ):(
                            props.instock ? (
                                <Link to={`/${props?.id}/${encodeURIComponent(props?.desc)}`} className="addtocart-btn btn w-max-content bg-brand-light2 mx-auto text-white rounded-pill body-font-semibold px-3 text-brand-dark">SHOP NOW</Link>
                            ): (
                                <button className="addtocart-btn btn w-max-content bg-brand-light2 mx-auto text-white rounded-pill body-font-semibold px-3">OUT OF STOCK</button>
                            )
                        )
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Procard;