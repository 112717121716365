import React, { useEffect, useState } from 'react';
import './Detail.css';
import { useParams } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Procard from '../../Components/Procard/Procard';
import { useAuth } from '../../Context/Authcontext';
import { HelmetProvider, Helmet } from 'react-helmet-async';

function Detail() {

    const { id } = useParams();
    const {userdata} = useAuth();
    const [prodata, setProdata] = useState({});
    const [imgurl, setImgurl] = useState([]);
    const [allpro, setAllpro] = useState([]);
    const [relatedpros, setRelatedpros] = useState([]);
    const [whaturl, setwhaturl] = useState('');
    const [shareurl, setShareurl] = useState('');
    const [popupurl, setPopupurl] = useState("");
    const [posix, setPosix] = useState("");
    const [posiy, setPosiy] = useState("");
    const [popdis, setPopdis] = useState("block");
    const [benidis, setBenidis] = useState("none");
    const [beniind, setBeniind] = useState(0);
    const [loaderdis, setLoaderdis] = useState('flex');

    useEffect(()=> window.scrollTo(0, 0), []);

    useEffect(() => {
        if(prodata?.name){
            setTimeout(() => {
                setLoaderdis('none');
            }, 1000);
        }else{
            setLoaderdis('flex')
        }
    }, [prodata]);
    
    useEffect(()=> window.scrollTo(0, 0), [id]);

    useEffect(() => {
        if(userdata){
            fetch(`https://shahstore.co.in/api/countview/${id}/${userdata?._id}`).then(res => res.json()).then(data => console.log(data)).catch(err => console.log('error occured while counting the view ', err));
        }
    }, [userdata, id]);

    useEffect(()=> {
        fetch(`https://shahstore.co.in/api/pro/${id}`).then(res => res.json()).then(data => {
            if(data.success){
                setProdata(data.pro);
            }else{
                console.log(data);
            }
        }).catch(err => console.log('error occured while getting the single data ', err));
    }, [id]);

    useEffect(()=> {
        fetch(`https://shahstore.co.in/api/`).then(res => res.json()).then(data => {
            if(data.success){
                // console.log(data);
                setAllpro(data.pro);
            }else{
                console.log(data);
            }
        }).catch(err => console.log('error occured while getting all the product ', err));
    }, []);

    useEffect(()=> {
        if(allpro?.length > 0){
            const samecat = allpro?.filter(e => e.category.toLowerCase() === prodata?.category?.toLowerCase());
            const rmsame = samecat.filter(e => e.proid !== id);
            // console.log(rmsame);
            setRelatedpros(rmsame);
        }
    }, [allpro, prodata, id])

    useEffect(()=> {
        if (prodata?.images) {
            setImgurl(prodata?.images);
        }    
    }, [prodata, setImgurl]);

    const herocaro = {
        customPaging: function (i) {
            return (
                <div className='dot-img p-0'>
                    <img src={imgurl[i]} alt="" className="img-fluid" />
                    <div className="detail-water">
                        <img src="/assets/watermark.webp" alt="watermark" className="img-fluid" />
                    </div>
                </div>
            )
        },
        dots: true,
        infinite: true,
        autoplay: true, 
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    }

    function capitalizeWords(text) {
        if(text){
            return text
            .split(' ') // Split the string into an array of words
            .map(word => {
                // Capitalize the first letter and concatenate it with the rest of the word
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            })
            .join(' '); // Join the array back into a single string
        }
    }

    useEffect(() => {
        // console.log('Image URL:', imgurl[0]);
        const name = capitalizeWords(prodata?.name) ? capitalizeWords(prodata?.name) : '';
        // const description = prodata?.description || '';
        const features = prodata?.features?.map(feat => `${capitalizeWords(feat.name)}: *${capitalizeWords(feat.description)}*`).join('\n');
        const waurl = `*${name}*\n\nSpecifications:\n${features}\n\nPrice: *₹${prodata?.discountprice}*\n\n${window.location.origin}/${id}/${encodeURIComponent(capitalizeWords(prodata?.name))}\n\nI want to buy this Watch!
        `;

        setwhaturl(`https://wa.me/+919082874084?text=${encodeURIComponent(waurl)}`);
    }, [prodata, id, imgurl]);

    useEffect(() => {
        // console.log('Image URL:', imgurl[0]);
        const name = capitalizeWords(prodata?.name) ? capitalizeWords(prodata?.name) : '';
        // const description = prodata?.description || '';
        const features = prodata?.features?.map(feat => `${capitalizeWords(feat.name)}: *${capitalizeWords(feat.description)}*`).join('\n');
        const waurl = `*${name}*\n\nSpecifications:\n${features}\n\nPrice: *₹${prodata?.discountprice}*\n\n${window.location.origin}/${id}/${encodeURIComponent(capitalizeWords(prodata?.name))}\n\nI want to know more about this Watch!
        `;

        setShareurl(`https://wa.me/?text=${encodeURIComponent(waurl)}`);
    }, [prodata, id, imgurl]);

    function createMarkup() {
        return {__html: prodata?.buylink};;
    }

    const mouseInFunc = (e) => {
        if(window.innerWidth < 800){
            
        }else{
            const element = e.currentTarget.getBoundingClientRect();
            // console.log(element)
            const posXPercent = ((e.clientX - element.left) / element.width) * 100;
            const posYPercent = ((e.clientY - element.top) / element.height) * 100;
            setPosix(`${Math.round(posXPercent)}%`);
            setPosiy(`${Math.round(posYPercent)}%`);
            // console.log(Math.round(posXPercent), Math.round(posYPercent))
        }
    }

    const handleTouchMove = (e) => {
        if (window.innerWidth < 800) {
            const touch = e.touches[0];
            const element = e.currentTarget.getBoundingClientRect();
            const posXPercent = (1 - (touch.clientX - element.left) / element.width) * 100;
            const posYPercent = (1 - (touch.clientY - element.top) / element.height) * 100;
            setPosix(`${Math.round(posXPercent)}%`);
            setPosiy(`${Math.round(posYPercent)}%`);
            // console.log(Math.round(posXPercent), Math.round(posYPercent));
        }
    };

    useEffect(() => {
        if (popupurl !== '') {
            document.querySelector('body').style.overflow = 'hidden';
        }else{
            document.querySelector('body').style.overflow = '';
        }
    }, [popupurl]);

    const beniFunc = (ind) => {
        setBeniind(ind);
        setBenidis('flex');
    }
    

  return (        
        <HelmetProvider>
            <Helmet>
                <title>{`${capitalizeWords(prodata?.name)} | Shah Store - Discover the Fashion of Your Wrist!`}</title>
                <meta name="description" content={prodata?.description}/>

                <meta property="og:url" content={window.location.href}/>
                {/* <meta property="og:type" content="website"/> */}
                <meta property="og:title" content={`${capitalizeWords(prodata?.name)} | Shah Store - Discover the Fashion of Your Wrist!`}/>
                <meta property="og:description" content={prodata?.description}/>
                <meta property="og:image" content={imgurl[0] || "https://png.pngtree.com/png-vector/20191126/ourmid/pngtree-image-of-cute-radish-vector-or-color-illustration-png-image_2040180.jpg"}/>
            </Helmet>
            
                    <> 
                        <div className='main-detail'>
                            <div className="container-fluid mx-auto mt-4">
                                <div className="row">
                                    {/* <div className="col-md-1 bg-danger"></div> */}
                                    <div className="col-md-4 p-2 pt-0 mb-4 detail-img-wrap">
                                        <Slider {...herocaro}>
                                            {
                                                imgurl?.map((url, ind) => (
                                                        <div key={ind} className="p-1">
                                                            <div className="detail-img rounded-brand overflow-hidden bg-brand-light cursor-pointer">
                                                                <img src={url} alt="girl img" className="img-fluid w-100" />
                                                                <div className="detail-water" onClick={()=> setPopupurl(url)}>
                                                                    <img src="/assets/watermark.webp" alt="watermark" className="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    
                                                }
                                        </Slider>
                                    </div>
                                    <div className="col-md-5 mb-5">
                                        <div className="name-wrap d-flex justify-content-between align-items-start">
                                            <div>
                                                {/* <p className="mb-0 body-font-bold text-muted text-capitalize">{prodata?.category}</p> */}
                                                <p className="mb-0 fs-3 body-font-bold text-capitalize"><span className="text-brand-brown body-font-extrabold">{capitalizeWords(prodata?.features?.length > 0 && prodata?.features[0]?.description)}</span> {prodata?.name}</p>
                                            </div>
                                            <div className="heart-wrap p-3 bg-brand-brown rounded-brand mt-2 ms-2">
                                                <a href={shareurl} target='_blank' rel='noreferrer' className="mb-0 p-0 lh-sm text-white"><i className="fas fa-share-alt fs-5"></i></a>
                                            </div>
                                        </div>
                                        <p className="mb-0 mt-4 fs-6 lh-sm text-muted body-font-semibold">{prodata?.description}</p>

                                        <div className="d-flex justify-content-between align-items-center mt-4">
                                            {
                                                prodata?.category === 'Swizz Eta Watch'? (
                                                    <p className="mb-0 fs-1 body-font-bold"><span className="body-font-bold text-brand-brown">SEE </span>PRICE</p>
                                                ): (
                                                    <p className="mb-0 fs-1 body-font-bold"><span className="body-font-bold text-brand-brown">₹</span>{prodata?.discountprice}/-</p>
                                                )
                                            }
                                            {
                                                prodata?.category === 'Swizz Eta Watch' ? (
                                                    <a href={whaturl} target='_blank' rel='noreferrer' className="btn btn-brand-brown px-4 py-2 body-font-bold fs-5 rounded-pill">DM for price</a>
                                                ): (
                                                    prodata?.instock? (
                                                        <a href={whaturl} target='_blank' rel='noreferrer' className="btn btn-brand-brown px-4 py-2 body-font-bold fs-5 rounded-pill">BUY NOW</a>
                                                    ): (
                                                        <button target='_blank' rel='noreferrer' className="btn btn-brand-brown px-4 py-2 body-font-bold fs-5 rounded-pill">OUT OF STOCK</button>
                                                    )
                                                )
                                            }
                                        </div>
                                        <div className="youtube-iframe-wrap mt-4">
                                            {
                                                prodata?.buylink?.length > 0 && (
                                                    <div dangerouslySetInnerHTML={createMarkup()}>
                                                    
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="mt-4">
                                            <p className="mb-2 body-font-bold fs-5 text-brand-brown">Specifications</p>
                                            <div className="bg-light p-3">
                                                {
                                                    prodata?.features?.map((feat, ind) => (
                                                        <p key={ind} className="mb-0 body-font-medium text-muted">{feat.name} - <span className="body-font-medium text-muted">{feat.description}</span></p>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="row px-2">
                                                <div className="col-6">
                                                    <div className="bane-card-wraper p-1 pt-0 cursor-pointer" onClick={() => beniFunc(0)}>
                                                        <div className="beni-card border border-brand-light-brown p-0 py-3 px-2 rounded-brand row align-items-center">
                                                            <div className="col-4 p-0 pe-2"><img src="/assets/cashondelivery.webp" alt="cash on delivery" className="img-fluid" /></div>
                                                            <div className="col-8 p-0">
                                                                <p className="mb-0 fs-sm lh-sm body-font-bold text-brand-brown">Cash on <br className=" card-break"/> Delivery</p>
                                                                {/* <p className="mb-0 fs-sm lh-sm body-font-bold">Available</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="bane-card-wraper p-1 pt-0 cursor-pointer" onClick={() => beniFunc(1)}>
                                                        <div className="beni-card border border-brand-light-brown p-0 py-3 px-2 rounded-brand row align-items-center">
                                                            <div className="col-4 p-0 pe-2"><img src="/assets/exchange.webp" alt="cash on delivery" className="img-fluid" /></div>
                                                            <div className="col-8 p-0">
                                                                <p className="mb-0 fs-sm lh-sm body-font-bold text-brand-brown">Exchange <br className=" card-break"/> Policy</p>
                                                                {/* <p className="mb-0 fs-sm lh-sm body-font-bold">Available</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="bane-card-wraper p-1 pt-0 cursor-pointer" onClick={() => beniFunc(2)}>
                                                        <div className="beni-card border border-brand-light-brown p-0 py-3 px-2 rounded-brand row align-items-center">
                                                            <div className="col-4 p-0 pe-2"><img src="/assets/premium-quality.webp" alt="cash on delivery" className="img-fluid" /></div>
                                                            <div className="col-8 p-0">
                                                                <p className="mb-0 fs-sm lh-sm body-font-bold text-brand-brown">Premium<br className=" card-break"/> Quality</p>
                                                                {/* <p className="mb-0 fs-sm lh-sm body-font-bold">Available</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="bane-card-wraper p-1 pt-0 cursor-pointer" onClick={() => beniFunc(3)}>
                                                        <div className="beni-card border border-brand-light-brown p-0 py-3 px-2 rounded-brand row align-items-center">
                                                            <div className="col-4 p-0 pe-2"><img src="/assets/commitment.webp" alt="cash on delivery" className="img-fluid" /></div>
                                                            <div className="col-8 p-0">
                                                                <p className="mb-0 fs-sm lh-sm body-font-bold text-brand-brown">100%<br className=" card-break"/> Trusted</p>
                                                                {/* <p className="mb-0 fs-sm lh-sm body-font-bold">Available</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="suggetions-wrap mt-5 mb-5">
                                    <h2 className="fs-4 body-font-bold text-center"><span className="text-brand-brown body-font-bold">Related</span> Products</h2>
                                    <div className="mt-4 row p-0 m-0">
                                        {/* <Slider {...relatedcaro}> */}
                                            {
                                                relatedpros.slice(0, 8).reverse()?.map((pro, ind) => (
                                                    <div key={ind} className="col-md-3 col-6 p-2 mb-3">
                                                        <Procard id={pro.proid} img={pro.images[0]} tag={pro.status} name={pro.brand} discountprice={pro.discountprice} desc={pro.name} price={pro.price} category={pro.category} star={pro.stars} reviewcount={pro.noreviews} instock={pro.instock} />
                                                    </div>
                                                ))
                                            }
                                        {/* </Slider> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            popupurl !== '' && (
                                <div className="image-popup-wrap">
                                    <div className="popup-cross d-flex justify-content-end"><p className="mb-0 w-max-content cursor-pointer" onClick={()=> setPopupurl('')}><i className="fas fa-times text-white fs-5"></i></p></div>
                                    <div className="img-popup pt-0" style={{ '--url': `url(${popupurl})`, '--posix': posix, '--posiy': posiy, '--popdis': popdis }} onMouseEnter={() => setPopdis('block')} onMouseMove={(e) => mouseInFunc(e)} onMouseLeave={() => setPopdis('none')}>
                                        <img src={popupurl} alt="popup preview" className="img-fluid" />
                                        <div className="watermark-wrap">
                                            <img src="/assets/watermark.webp" alt="watermark" />
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            popupurl !== '' && (
                                <div className="image-popup-wrap-mob px-2">
                                    <div className="popup-cross-mob d-flex px-3 justify-content-end w-100"><p className="mb-0 w-max-content cursor-pointer" onClick={()=> setPopupurl('')}><i className="fas fa-times text-white fs-5"></i></p></div>
                                    <div className="img-popup-mob p-3 pt-0" style={{ '--url': `url(${popupurl})`, '--posix': posix, '--posiy': posiy, '--popdis': popdis }} onTouchMove={(e) => handleTouchMove(e)}>
                                        <img src={popupurl} alt="popup preview" className="img-fluid" />
                                        <div className="watermark-wrap">
                                            <img src="/assets/watermark.webp" alt="watermark" />
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        <div className="benefit-card-wrap" style={{display: benidis}}>
                            {
                                beniind === 0 && (
                                    <div className="pop-wraper">
                                        <div className="benifit-card d-flex justify-content-end pe-3 mx-auto"><p className="mb-0 cursor-pointer w-max-content" onClick={() => setBenidis('none')}><i className="fas fa-times fs-5 text-white"></i></p></div>
                                        <div className="benifit-card bg-white overflow-hidden p-3 mx-auto">
                                            <p className="fs-5 body-font-bold text-center">Cash on delivery policy</p>
                                            <ol>
                                                <li className="mb-0">300 advance payment for cash on delivery.</li>
                                                <li className="mb-0">No extra, no hidden charges in COD.</li>
                                                <li className="mb-0">300 advance rest amount will be COD.</li>
                                                <li className="mb-0">No Booking without advance.</li>
                                                <li className="mb-0">For urgent delivery extra charges.</li>
                                            </ol>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                beniind === 1 && (
                                    <div className="pop-wraper">
                                        <div className="benifit-card d-flex justify-content-end pe-3 mx-auto"><p className="mb-0 cursor-pointer w-max-content" onClick={() => setBenidis('none')}><i className="fas fa-times fs-5 text-white"></i></p></div>
                                        <div className="benifit-card bg-white overflow-hidden p-3 mx-auto">
                                            <p className="fs-5 body-font-bold text-center">Exchange Policy Terms & Condition</p>
                                            <ol>
                                                <li className="mb-0">No refund, no return only exchange.</li>
                                                <li className="mb-0">Both courier charges will paid by costumer.</li>
                                                <li className="mb-0">Once we check parcel then we will proceed for exchange.</li>
                                                <li className="mb-0">If product sended by costumer is defect or wrong then no exchange.</li>
                                                <li className="mb-0">Exchange within 24hrs after receiving.</li>
                                            </ol>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                beniind === 2 && (
                                    <div className="pop-wraper">
                                        <div className="benifit-card d-flex justify-content-end pe-3 mx-auto"><p className="mb-0 cursor-pointer w-max-content" onClick={() => setBenidis('none')}><i className="fas fa-times fs-5 text-white"></i></p></div>
                                        <div className="benifit-card bg-white overflow-hidden p-3 mx-auto">
                                            <p className="fs-5 body-font-bold text-center">Premium Quality</p>
                                            <ol>
                                                <li className="mb-0">Hamari company sirf premium quality me hi deal karti hai jaise Japan Quality, Semi ETA Quality, Swiss ETA quality with 1 year warranty.</li>
                                                <li className="mb-0">Our main aim is to fulfill the dream of common man by providing the premium quality First Copy Watches in India at an affordable price.</li>
                                                <li className="mb-0">We provide 100% Swiss Eta watches at the best price. We have Swiss collection from mostly all reputed factories like ZF,EW,MP,BV,TW,GM,VS,APS,AWB,Clean,THB etc.</li>
                                            </ol>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                beniind === 3 && (
                                    <div className="pop-wraper">
                                        <div className="benifit-card d-flex justify-content-end pe-3 mx-auto"><p className="mb-0 cursor-pointer w-max-content" onClick={() => setBenidis('none')}><i className="fas fa-times fs-5 text-white"></i></p></div>
                                        <div className="benifit-card bg-white overflow-hidden p-3 mx-auto">
                                            <p className="fs-5 body-font-bold text-center">100% Trusted</p>
                                            <p className="mb-0">
                                                Shah Store Founded in 2019, our company has been dedicated to offering high-quality, stylish watches that cater to diverse tastes. With a growing base of happy customers, we pride ourselves on providing excellent customer service and delivering timeless pieces that add elegance to every wrist.
                                            </p>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </>
                    <div className="loader-wrpper" style={{display: loaderdis}}>
                        <div className="loader-wrap">
                            <img src="/assets/loader-logo.webp" alt="loader logo" className="img-fluid" />
                        </div>
                    </div>
                

        </HelmetProvider>
  )
}

export default Detail;