import React, { useEffect, useState } from 'react';
import './Home.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from 'react-router-dom';
import Categorycard from '../../Components/Categorycard/Categorycard';
import Procard from '../../Components/Procard/Procard';

function Home() {

    const navigate = useNavigate();
    const [cattabs, setCattabs] = useState(1);
    const [prodata, setProdata] = useState([]);
    const [brandpop, setBrandpop] = useState('none');
    const [tempcat, setTempcat] = useState('');
    const [catestocks, setCatestocks] = useState({});

    useEffect(()=> window.scrollTo(0, 0), []);
    
    const herocaro = {
        infinite: true,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    }
    const bannerCaro = {
        infinite: true,
        dots: true,
        arrows: false,
        slidesToShow: 3,
        autoplay: true,
        slidesToScroll: 1,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    }
    const mobherocaro = {
        infinite: true,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    }
    const reelcaro = {
        infinite: true,
        dots: false,
        arrows: false,
        slidesToShow: 5,
        autoplay: false,
        slidesToScroll: 5,
        pauseOnHover: false,
        initialSlide: 0,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots: false,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots: false,
                },
            },
        ],
    };
    const reviewcaro = {
        infinite: true,
        dots: false,
        arrows: false,
        slidesToShow: 5,
        autoplay: true,
        slidesToScroll: 1,
        pauseOnHover: false,
        initialSlide: 0,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots: false,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots: false,
                },
            },
        ],
    };

    useEffect(()=> {
        fetch(`https://shahstore.co.in/api/`).then(res => res.json()).then(data => {
            if(data.success){
                console.log(data);
                console.log(data.pro[0].colornimage)
                setProdata(data.pro.reverse());
            }else{
                console.log(data);
            }
        }).catch(err => console.log('error occured while getting all the pros ', err));
    }, []);

    useEffect(()=> {
        const countobj = {};
        const menwat = prodata?.filter(e => e.category.toLowerCase() === 'men watch');
        const woamnwat = prodata?.filter(e => e.category.toLowerCase() === 'women watch');
        const swizzwat = prodata?.filter(e => e.category.toLowerCase() === 'swizz eta watch');
        const celewat = prodata?.filter(e => e.category.toLowerCase() === 'celebrity watch');
        countobj['men watch'] = menwat.length;
        countobj['women watch'] = woamnwat.length;
        countobj['swizz eta watch'] = swizzwat.length;
        countobj['celebrity watch'] = celewat.length;
        setCatestocks(countobj);
    }, [prodata]);

    // const splitFunc = (str)=> {
    //     const imgFst = str?.split(', ');
    //     console.log(imgFst)
    //     return imgFst;
    // };

    const mensbrand = ["Audemars Piguet", "Bell & Ross", "Breitling", "Bvlgari", "Cartier", "Franck Muller", "Gucci", "Hublot", "IWC (Schaffhausen)", "Jacob & Co.", "Jaeger Le-coultre (JLC)", "Luminor Panerai", "Omega", "Patek Philippe", "Richard Mille", "Roger Dubuis", "Rolex", "Seiko", "Seven Friday", "Tag Heuer", "Tissot", "Tudor", "U-boat", "Ulysse Nardin", "Armani Exchange (OG)", "Hugo boss(OG)", "Guess(OG)", "Maserati (og)"];
    // const womenbrand = ["Armeni", "Audemars Piguet", "Casio", "Coach", "Guess", "Rado", "Micheal kors", "Rolex"];

    return (
        <div className='main-home overflow-hidden'>
            <div className="container-fluid desk-hero mx-auto bg-brand-light m-0 py-5 d-none">
                <Slider {...herocaro}>
                    <div className="slider-wrap">
                        <div className="main-hero-div row align-items-center container mx-auto p-0 m-0">
                            <div className="col-md-6">
                                <p className="mb-0 text-dark text-muted body-font-semibold">SALE! UP TO 50% OFF!</p>
                                <p className="mb-0 hero-text fs-1 text-dark head-font-bold lh-sm">Exclusive Collection of Men Watches</p>
                                <button className="btn btn-brand-brown px-4 py-2 body-font-semibold rounded-3 mt-4">Shop Now</button>
                            </div>
                            <div className="col-md-6">
                                {/* <img src="/assets/hero-accessor1.webp" alt="" className="img-fluid w-100" /> */}
                                <img src="https://inventstore.in/wp-content/uploads/2023/09/watch-ultra-double-1.webp" alt="" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                    <div className="slider-wrap">
                        <div className="main-hero-div row align-items-center container mx-auto p-0 m-0">
                            <div className="col-md-6">
                                <p className="mb-0 text-dark text-muted body-font-semibold">SALE! UP TO 50% OFF!</p>
                                <p className="mb-0 hero-text fs-1 text-dark head-font-bold lh-sm">Best Women Watches to Look More Attractive</p>
                                <button className="btn btn-brand-brown px-4 py-2 body-font-semibold rounded-3 mt-4">Shop Now</button>
                            </div>
                            <div className="col-md-6">
                                {/* <img src="/assets/hero-kitchen1.webp" alt="" className="img-fluid w-100" /> */}
                                <img src="https://eu.danielwellington.com/cdn/shop/products/c8bd0bbe58c25c0caf52806dd0362df62569d70d.png?v=1686314686&width=1500" alt="" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                    <div className="slider-wrap">
                        <div className="main-hero-div row align-items-center container mx-auto p-0 m-0">
                            <div className="col-md-6">
                                <p className="mb-0 text-dark text-muted body-font-semibold">SALE! UP TO 50% OFF!</p>
                                <p className="mb-0 hero-text fs-1 text-dark head-font-bold lh-sm">Get the Watches Worn by Celebrities</p>
                                <button className="btn btn-brand-brown px-4 py-2 body-font-semibold rounded-3 mt-4">Shop Now</button>
                            </div>
                            <div className="col-md-6 p-0">
                                {/* <img src="/assets/hero-cleaning1.webp" alt="" className="img-fluid w-100" /> */}
                                <img src="https://www.gonoise.com/cdn/shop/products/1.6_44e4e36e-f762-4c89-b950-700aa320a935.png?v=1681610912" alt="" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
            <div className="container-fluid mob-hero bg-brand-light p-0 py-5 pb-4 d-none">
                <div className="banner-wrap mb-3">
                    <div className="hero-banner">
                        <Slider {...mobherocaro}>
                            <div className="hero-banner-wrapper">
                                <div className="row justify-content-between align-items-center py-0 pt-4 w-100 ps-3">
                                    <div className="hero-banner-text col-md-6">
                                        <p className="mb-0 text-muted">UPTO 50% OFF</p>
                                        <h2 className="mb-0 fs-4 body-font-bold mt-1 text-nowrap">Daily Grocery Order and Get <br /> Express Delivery</h2>
                                        <button className="btn btn-brand-brown px-3 rounded-brand-sm mt-3">Shop Now</button>
                                    </div>
                                    <div className="col-md-6 pt-4">
                                        <img src="https://inventstore.in/wp-content/uploads/2023/09/watch-ultra-double-1.webp" alt="" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="hero-banner-wrapper">
                                <div className="row justify-content-between align-items-center py-0 pt-4 w-100 ps-3">
                                    <div className="hero-banner-text col-md-6">
                                        <p className="mb-0 text-muted">UPTO 50% OFF</p>
                                        <h2 className="mb-0 fs-4 body-font-bold mt-1 text-nowrap">Daily Grocery Order and Get <br /> Express Delivery</h2>
                                        <button className="btn btn-brand-brown px-3 rounded-brand-sm mt-3">Shop Now</button>
                                    </div>
                                    <div className="col-md-6 pt-4">
                                        <img src="https://eu.danielwellington.com/cdn/shop/products/c8bd0bbe58c25c0caf52806dd0362df62569d70d.png?v=1686314686&width=1500" alt="" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="hero-banner-wrapper">
                                <div className="row justify-content-between align-items-center py-0 pt-4 w-100 ps-3">
                                    <div className="hero-banner-text col-md-6">
                                        <p className="mb-0 text-muted">UPTO 50% OFF</p>
                                        <h2 className="mb-0 fs-4 body-font-bold mt-1 text-nowrap">Daily Grocery Order and Get <br /> Express Delivery</h2>
                                        <button className="btn btn-brand-brown px-3 rounded-brand-sm mt-3">Shop Now</button>
                                    </div>
                                    <div className="col-md-6 pt-4">
                                        <img src="https://www.gonoise.com/cdn/shop/products/1.6_44e4e36e-f762-4c89-b950-700aa320a935.png?v=1681610912" alt="" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
            <div className="container-fluid p-0 hero-banner-wrap">
                <img src="/assets/hero-banner.webp" alt="" className="img-fluid" />
            </div>
            <div className="container-fluid p-0 mt-5">
                <h2 className="fs-4 head-font-bold mb-3 px-3 text-center">Category</h2>
                <div className="row p-0 m-0 px-2 justify-content-center">
                    <div className="col-md-2 col-6 p-1 m-0 mb-2" onClick={() => {setBrandpop('flex'); setTempcat('men-watch')}}>
                        <Categorycard cate='men-watch' img="./assets/men-watch-new.webp" name="men watch" stock={catestocks['men watch']}/>
                    </div>
                    <div className="col-md-2 col-6 p-1 m-0 mb-2" onClick={() => {navigate('/product/women-watch')}}>
                        <Categorycard cate='women-watch' img="./assets/women-watch-new.webp" name="women watch" stock={catestocks['women watch']}/>
                    </div>
                    <div className="col-md-2 col-6 p-1 m-0 mb-2" onClick={() => {navigate('/product/swizz-eta-watch')}}>
                        <Categorycard cate='swizz-eta-watch' img="./assets/swiss-eta-new.webp" name="swizz eta watch" stock={catestocks['swizz eta watch']}/>
                    </div>
                    <div className="col-md-2 col-6 p-1 m-0 mb-2" onClick={() => {navigate('/product/celebrity-watch')}}>
                        <Categorycard cate='celebrity-watch' img="./assets/celebrity-watch-new.webp" name="celebrity watch" stock={catestocks['celebrity watch']}/>
                    </div>
                    <div className="col-md-4 col-8 ps-4 d-none">
                        <div className="pe-3">
                            <div className="celeb-cate-card bg-brand-light rounded-brand row align-items-center justify-content-between overflow-hidden py-1">
                                <div className="celeb-img-wrap col-5 p-0">
                                    <img src="./assets/CELEBRITY.webp" alt="celeb card" className="img-fluid" />
                                </div>
                                <div className="col-6">
                                    <p className="mb-0 fs-5 body-font-bold">Celebrity <br /> Watch</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-5 d-none">
                <h2 className="fs-4 head-font-bold mb-3">Shop By Brand</h2>
                <div className="row">
                    <div className="col-md-2 col-4 px-2 cursor-pointer mb-3">
                        <div className="brand-card rounded-brand-sm border border-brand-light-brown p-3">
                            <img src="./assets/fossil.webp" alt="fossil logo" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 px-2 cursor-pointer mb-3">
                        <div className="brand-card rounded-brand-sm border border-brand-light-brown p-3">
                            <img src="./assets/rolex.webp" alt="fossil logo" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 px-2 cursor-pointer mb-3">
                        <div className="brand-card rounded-brand-sm border border-brand-light-brown p-3">
                            <img src="./assets/tommy.webp" alt="fossil logo" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 px-2 cursor-pointer mb-3">
                        <div className="brand-card rounded-brand-sm border border-brand-light-brown p-3">
                            <img src="./assets/armeni.webp" alt="fossil logo" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 px-2 cursor-pointer mb-3">
                        <div className="brand-card rounded-brand-sm border border-brand-light-brown p-3">
                            <img src="./assets/gshock.webp" alt="fossil logo" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 px-2 cursor-pointer mb-3">
                        <div className="brand-card rounded-brand-sm border border-brand-light-brown p-3">
                            <img src="./assets/citizen.svg" alt="fossil logo" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5 mt-0">
                <div className="banner-wrap desk-banner container">
                    <Slider {...bannerCaro}>
                        <Link to="/product" className='nav-link px-2'>
                            <img src="./assets/banner-new-3.webp" alt="" className="img-fluid w-100" />
                        </Link>
                        <Link to="/product" className='nav-link px-2'>
                            <img src="./assets/banner-new-2.webp" alt="" className="img-fluid w-100" />
                        </Link>
                        <a href="https://wa.me/+919082874084" target='_blank' rel='noreferrer' className='nav-link px-2'>
                            <img src="./assets/banner-new-1.webp" alt="" className="img-fluid w-100" />
                        </a>
                    </Slider>
                </div>
                <div className="banner-wrap mob-banner">
                    <Slider {...bannerCaro}>
                        <Link to="/product" className='nav-link'>
                            <img src="./assets/banner-new-3.webp" alt="" className="img-fluid w-100 rounded-brand" />
                        </Link>
                        <Link to="/product" className='nav-link'>
                            <img src="./assets/banner-new-2.webp" alt="" className="img-fluid w-100 rounded-brand" />
                        </Link>
                        <a href="https://wa.me/+919082874084" target='_blank' rel='noreferrer' className='nav-link'>
                            <img src="./assets/banner-new-1.webp" alt="" className="img-fluid w-100 rounded-brand" />
                        </a>
                    </Slider>
                </div>
            </div>
            <div className="container-fluid">
                <div className="whats-new-div mt-5">
                <h2 className="fs-4 head-font-bold text-center mb-0">Our <span className="text-brand-light-brown head-font-bold">Latest</span> Collection</h2>
                    <div className="cat-tabs-wrap desk-tabs mt-4 d-none">
                        <div className="cat-tabs bg-brand-light d-flex align-items-center rounded-brand w-max-content mx-auto p-2">
                            {/* <div className="tab-back bg-white shadow-sm rounded-brand-sm" style={{ left: cattabs === 1 ? 10 : 150 * (cattabs - 1) }}></div> */}
                            <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer text-uppercase ${cattabs === 1 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(1)}>crush</p>
                            <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer text-uppercase ${cattabs === 2 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(2)}>chocolate</p>
                            <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer text-uppercase ${cattabs === 3 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(3)}>honey</p>
                            <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer text-uppercase ${cattabs === 4 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(4)}>syrup</p>
                            <p className={`mb-0 me-0 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer text-uppercase ${cattabs === 5 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(5)}>powder</p>
                        </div>
                    </div>
                    <div className="cat-tabs-wrap mob-tabs mt-4 d-none">
                        <div className="cat-tabs bg-brand-light d-flex align-items-center rounded-brand w-max-content mx-auto p-2">
                            <div className="tab-back bg-white shadow-sm rounded-brand-sm" style={{ left: cattabs === 1 ? 10 : 100 * (cattabs - 1) }}></div>
                            <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer text-uppercase ${cattabs === 1 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(1)}>honey</p>
                            <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer text-uppercase ${cattabs === 2 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(2)}>crush</p>
                            <p className={`mb-0 me-0 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer text-uppercase ${cattabs === 3 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(3)}>syrup</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="whats-new-card-wrap mt-0 pb-5">
                    <div className="new-cards row">
                        {
                            prodata?.length > 0 && (
                                prodata?.slice(0, 4).map((pro, ind) => (
                                    <div key={ind} className="col-md-3 col-6 p-2 mb-3">
                                        <Procard id={pro.proid} img={pro.images[0]} tag={pro.status} name={pro.brand} discountprice={pro.discountprice} desc={pro.name} price={pro.price} category={pro.category} star={pro.stars} reviewcount={pro.noreviews} instock={pro.instock} />
                                    </div>
                                ))
                            )
                        }
                    </div>
                    <div className="mx-auto w-max-content mt-3 cursor-pointer">
                        <Link to="/product" className="btn btn-brand-brown mx-auto body-font-semibold px-3 rounded-brand-sm">VIEW ALL</Link>
                    </div>
                </div>
            </div>

            <div className="reel-wraper container-fluid p-0 mb-5">
                <div className="d-flex align-items-center justify-content-between mb-3 px-2">
                    <h2 className="fs-5 head-font-bold mb-0">Our <span className="text-brand-insta head-font-bold">Instagram</span> Reels</h2>
                    <div className="insta-id-wrap d-flex rounded-pill border border-brand-light">
                        <a href='https://www.instagram.com/shah.store_72?igsh=MWE5M2hkNXg3anBvNQ==' target='_blank' rel='noreferrer' className="nav-link insta-follow mb-0 body-font-bold px-2">@shahstore_72</a>
                        <a href='https://www.instagram.com/shah.store_72?igsh=MWE5M2hkNXg3anBvNQ==' target='_blank' rel='noreferrer' className="nav-link insta-follow mb-0 text-nowrap rounded-pill bg-brand-insta text-white px-2"><i className="fab fa-instagram me-1"></i>Follow</a>
                    </div>
                </div>
                <div className="reel-wrap p-3 bg-brand-light">
                    <Slider {...reelcaro}>
                        <div className="pe-2">
                            <div className="reel-card rounded-brand overflow-hidden border border-danger">
                                <a href="https://www.instagram.com/reel/C_zrfgHIJKJ/?igsh=MWhxazFucGYwMzA2eA==" target='_blank' rel='noreferrer' className="nav-link">
                                    <img src="/assets/reel4.webp" alt="" className="img-fluid" />
                                    <p className="reel-view body-font-medium text-white mb-0 fs-sm"><i className="fi fi-rr-play me-1 mt-1"></i> 1.8M</p>
                                </a>
                            </div>
                        </div>
                        <div className="pe-2">
                            <div className="reel-card rounded-brand overflow-hidden border border-danger">
                                <a href="https://www.instagram.com/reel/C--OswWiQ5J/?igsh=OXZ4MWFlZzlndmRx" target='_blank' rel='noreferrer' className="nav-link">
                                    <img src="/assets/reel5.webp" alt="" className="img-fluid" />
                                    <p className="reel-view body-font-medium text-white mb-0 fs-sm"><i className="fi fi-rr-play me-1 mt-1"></i> 158K</p>
                                </a>
                            </div>
                        </div>
                        <div className="pe-2">
                            <div className="reel-card rounded-brand overflow-hidden border border-danger">
                                <a href="https://www.instagram.com/reel/DAOAw0uSCit/?igsh=MWRwbjYyeDB4ZXU0cg==" target='_blank' rel='noreferrer' className="nav-link">
                                    <img src="/assets/reel6.webp" alt="" className="img-fluid" />
                                    <p className="reel-view body-font-medium text-white mb-0 fs-sm"><i className="fi fi-rr-play me-1 mt-1"></i> 31.7K</p>
                                </a>
                            </div>
                        </div>
                        <div className="pe-2">
                            <div className="reel-card rounded-brand overflow-hidden border border-danger">
                                <a href="https://www.instagram.com/reel/C7Jq0QDIDHh/" target='_blank' rel='noreferrer' className="nav-link">
                                    <img src="/assets/reel7.webp" alt="" className="img-fluid" />
                                    <p className="reel-view body-font-medium text-white mb-0 fs-sm"><i className="fi fi-rr-play me-1 mt-1"></i> 84.7K</p>
                                </a>
                            </div>
                        </div>
                        <div className="pe-2">
                            <div className="reel-card rounded-brand overflow-hidden border border-danger">
                                <a href="https://www.instagram.com/reel/DCW6h6Otgmc/?igsh=MTRyOXc2YXZpMmk2Yg==" target='_blank' rel='noreferrer' className="nav-link">
                                    <img src="/assets/reel8.webp" alt="" className="img-fluid" />
                                    <p className="reel-view body-font-medium text-white mb-0 fs-sm"><i className="fi fi-rr-play me-1 mt-1"></i> 2,536</p>
                                </a>
                            </div>
                        </div>
                        <div className="pe-2">
                            <div className="reel-card rounded-brand overflow-hidden border border-danger">
                                <a href="https://www.instagram.com/reel/DAOONkYSptg/?igsh=NG9wcWF4eGZnbTJj" target='_blank' rel='noreferrer' className="nav-link">
                                    <img src="/assets/reel9.webp" alt="" className="img-fluid" />
                                    <p className="reel-view body-font-medium text-white mb-0 fs-sm"><i className="fi fi-rr-play me-1 mt-1"></i> 9,470</p>
                                </a>
                            </div>
                        </div>
                        <div className="pe-2">
                            <div className="reel-card rounded-brand overflow-hidden border border-danger">
                                <a href="https://www.instagram.com/reel/C-IIYX6vkaN/?igsh=OGxvNnk4c2FzZWdy" target='_blank' rel='noreferrer' className="nav-link">
                                    <img src="/assets/reel10.webp" alt="" className="img-fluid" />
                                    <p className="reel-view body-font-medium text-white mb-0 fs-sm"><i className="fi fi-rr-play me-1 mt-1"></i> 14.6K</p>
                                </a>
                            </div>
                        </div>
                        <div className="pe-2">
                            <div className="reel-card rounded-brand overflow-hidden border border-danger">
                                <a href="https://www.instagram.com/reel/DCMIYVkNXdK/" target='_blank' rel='noreferrer' className="nav-link">
                                    <img src="/assets/reel11.webp" alt="" className="img-fluid" />
                                    <p className="reel-view body-font-medium text-white mb-0 fs-sm"><i className="fi fi-rr-play me-1 mt-1"></i> 3,899</p>
                                </a>
                            </div>
                        </div>
                        <div className="pe-2">
                            <div className="reel-card rounded-brand overflow-hidden border border-danger">
                                <a href="https://www.instagram.com/reel/DCZbNPdNYMF/" target='_blank' rel='noreferrer' className="nav-link">
                                    <img src="/assets/reel12.webp" alt="" className="img-fluid" />
                                    <p className="reel-view body-font-medium text-white mb-0 fs-sm"><i className="fi fi-rr-play me-1 mt-1"></i> 3,750</p>
                                </a>
                            </div>
                        </div>
                        <div className="pe-2">
                            <div className="reel-card rounded-brand overflow-hidden border border-danger">
                                <a href="https://www.instagram.com/reel/DCW6h6Otgmc/?igsh=MTRyOXc2YXZpMmk2Yg%3D%3D" target='_blank' rel='noreferrer' className="nav-link">
                                    <img src="/assets/reel13.webp" alt="" className="img-fluid" />
                                    <p className="reel-view body-font-medium text-white mb-0 fs-sm"><i className="fi fi-rr-play me-1 mt-1"></i> 5,485</p>
                                </a>
                            </div>
                        </div>
                        <div className="pe-2">
                            <div className="reel-card rounded-brand overflow-hidden border border-danger">
                                <a href="https://www.instagram.com/reel/DCenvpuNlAw/?igsh=MTlqaTlnejM3OTBsaA%3D%3D" target='_blank' rel='noreferrer' className="nav-link">
                                    <img src="/assets/reel14.webp" alt="" className="img-fluid" />
                                    <p className="reel-view body-font-medium text-white mb-0 fs-sm"><i className="fi fi-rr-play me-1 mt-1"></i> 3,864</p>
                                </a>
                            </div>
                        </div>
                        <div className="pe-2">
                            <div className="reel-card rounded-brand overflow-hidden border border-danger">
                                <a href="https://www.instagram.com/reel/DCo7a8HtSsQ/" target='_blank' rel='noreferrer' className="nav-link">
                                    <img src="/assets/reel15.webp" alt="" className="img-fluid" />
                                    <p className="reel-view body-font-medium text-white mb-0 fs-sm"><i className="fi fi-rr-play me-1 mt-1"></i> 3,352</p>
                                </a>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>

            <div className="better-experience container-fluid mb-5">
                <h2 className="fs-4 head-font-bold mb-3 text-center">For Your <span className="text-brand-light-brown head-font-bold">Better Experience</span></h2>
                <div className="experience-card-wrap row px-2">
                    <div className="col-md-4">
                        <div className="experience-card bg-brand-light rounded-brand p-3 ps-1 row align-items-center m-0 mb-3">
                            <div className="col-6">
                                <img src="./assets/circle-logo.webp" alt="circle logo" className="img-fluid" />
                            </div>
                            <div className="col-6 ps-0 d-flex flex-column justify-content-between">
                                <p className="mb-2 fs-2 text-brand-brown head-font-bold lh-sm">Shah Store</p>
                                <div className="m-0 p-0">
                                    <p className="m-0 body-font-extrabold">@shah.store_72</p>
                                    <p className="mb-0 lh-sm follow-text">Follow Us on Instagram and get the latest update of our Premium Watches.</p>
                                </div>
                                <a href='https://www.instagram.com/shah.store_72?igsh=MWE5M2hkNXg3anBvNQ==' target='_blank' rel='noreferrer' className="mb-0 nav-link rounded-pill fs-esm py-1 w-max-content bg-brand-insta text-white px-3 mt-2"><i className="fab fa-instagram me-1"></i>FOLLOW US</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="experience-card bg-brand-light rounded-brand p-3 ps-1 row align-items-center m-0 mb-3">
                            <div className="col-6">
                                <img src="./assets/circle-logo.webp" alt="circle logo" className="img-fluid" />
                            </div>
                            <div className="col-6 ps-0 d-flex flex-column justify-content-between">
                                <p className="mb-2 fs-2 text-brand-brown head-font-bold lh-sm">Shah’s Storee</p>
                                <div className="m-0 p-0">
                                    <p className="m-0 body-font-extrabold">@ShahsStoree</p>
                                    <p className="mb-0 lh-sm follow-text">Subscribe to our Youtube Channel & get the latest update of our Great Watches.</p>
                                </div>
                                <a href='https://youtube.com/@shahsstoree?si=MFYGbDuNezk1Vef-' target='_blank' rel='noreferrer' className="mb-0 nav-link rounded-pill fs-esm py-1 w-max-content bg-brand-youtu text-white px-3 mt-2"><i className="fab fa-youtube me-1"></i>SUBSCRIBE</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="experience-card bg-brand-light rounded-brand p-3 ps-1 row align-items-center m-0 mb-3">
                            <div className="col-6">
                                <img src="./assets/circle-logo.webp" alt="circle logo" className="img-fluid" />
                            </div>
                            <div className="col-6 ps-0 d-flex flex-column justify-content-between">
                                <p className="mb-2 fs-2 text-brand-brown head-font-bold lh-sm">Customer Support</p>
                                <div className="m-0 p-0">
                                    <p className="mb-0 lh-sm follow-text">Please Chat with us on WhatsApp regarding any issue or suggestions.</p>
                                </div>
                                <a href='https://wa.me/+919082874084' target='_blank' rel='noreferrer' className="mb-0 nav-link rounded-pill fs-esm py-1 w-max-content bg-brand-whats text-white px-3 mt-2"><i className="fab fa-whatsapp me-1"></i>CHAT NOW</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid mb-5">
                <h2 className="fs-4 head-font-bold mb-2 text-center">What our <span className="text-brand-light-brown head-font-bold">Customer Says</span> for Us!</h2>
                <div className="review-card-wrap mt-4">
                    <Slider {...reviewcaro}>
                        <div className="reviewimg-wrap px-3">
                            <img src="/assets/review1.webp" alt="review" className="img-fluid" />
                        </div>
                        <div className="reviewimg-wrap px-3">
                            <img src="/assets/review3.webp" alt="review" className="img-fluid" />
                        </div>
                        <div className="reviewimg-wrap px-3">
                            <img src="/assets/review4.webp" alt="review" className="img-fluid" />
                        </div>
                        <div className="reviewimg-wrap px-3">
                            <img src="/assets/review5.webp" alt="review" className="img-fluid" />
                        </div>
                        <div className="reviewimg-wrap px-3">
                            <img src="/assets/review6.webp" alt="review" className="img-fluid" />
                        </div>
                        <div className="reviewimg-wrap px-3">
                            <img src="/assets/review7.webp" alt="review" className="img-fluid" />
                        </div>
                        <div className="reviewimg-wrap px-3">
                            <img src="/assets/review8.webp" alt="review" className="img-fluid" />
                        </div>
                        <div className="reviewimg-wrap px-3">
                            <img src="/assets/review9.webp" alt="review" className="img-fluid" />
                        </div>
                    </Slider>
                </div>
                {/* <div className="review-card-wrap px-3 d-none">
                    <Slider {...reviewcaro}>
                        <div className="pt-5">
                            <div className="review-card bg-brand-light rounded-brand p-3">
                                <p className="mb-0 star-para px-4 py-2 border border-brand-light-brown rounded-pill w-max-content mx-auto text-warning bg-white">
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-2"></i>
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-2"></i>
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-2"></i>
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-2"></i>
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-0"></i>
                                </p>
                                <p className="mb-0 text-center lh-sm px-3 pt-4">
                                    Have been buying from shahstore.co.in for years now, always satisfied with any purchases made. Excellent range of watches at great prices.
                                </p>
                                <p className="mt-3 body-font-extrabold text-center"><i className="fas fa-user text-brand-brown me-2"></i>Rakesh Chaurasia</p>
                            </div>
                        </div>
                        <div className="pt-5">
                            <div className="review-card bg-brand-light rounded-brand p-3">
                                <p className="mb-0 star-para px-4 py-2 border border-brand-light-brown rounded-pill w-max-content mx-auto text-warning bg-white">
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-2"></i>
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-2"></i>
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-2"></i>
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-2"></i>
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-0"></i>
                                </p>
                                <p className="mb-0 text-center lh-sm px-3 pt-4">
                                    Have been buying from shahstore.co.in for years now, always satisfied with any purchases made. Excellent range of watches at great prices.
                                </p>
                                <p className="mt-3 body-font-extrabold text-center"><i className="fas fa-user text-brand-brown me-2"></i>Rakesh Chaurasia</p>
                            </div>
                        </div>
                        <div className="pt-5">
                            <div className="review-card bg-brand-light rounded-brand p-3">
                                <p className="mb-0 star-para px-4 py-2 border border-brand-light-brown rounded-pill w-max-content mx-auto text-warning bg-white">
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-2"></i>
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-2"></i>
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-2"></i>
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-2"></i>
                                    <i className="fi fi-ss-star fs-5 mb-0 p-0 me-0"></i>
                                </p>
                                <p className="mb-0 text-center lh-sm px-3 pt-4">
                                    Have been buying from shahstore.co.in for years now, always satisfied with any purchases made. Excellent range of watches at great prices.
                                </p>
                                <p className="mt-3 body-font-extrabold text-center"><i className="fas fa-user text-brand-brown me-2"></i>Rakesh Chaurasia</p>
                            </div>
                        </div>
                    </Slider>
                </div> */}
            </div>

            <div className="brand-popup-wrap px-3" style={{display: brandpop}}>
                <div className="brnad-popup bg-white py-4 px-2">
                    <div className="brand-pop-cross" onClick={() => setBrandpop('none')}>
                        <p className="mb-0 cursor-pointer"><i className="fas fa-times text-white"></i></p>
                    </div>
                    <h2 className="head-font-bold text-center fs-1 mt-3">Select the Brand</h2>
                    <div className="mt-4 row px-3">
                        {
                            mensbrand.map((brn, ind) => (
                                <div key={ind} className="col-6 p-1">
                                    <Link to={`/product/${tempcat}/${brn.trim().toLowerCase().replace(/-/g, '_').replace(/ /g, '-')}`} className='nav-link'>
                                        <div className="mb-0 d-flex align-items-center justify-content-between brand-tab rounded-pill p-1 ps-2">
                                            <p className="mb-0 poptext text-uppercase body-font-bold">{brn}</p>
                                            <p className="m-0 brand-tab-arrow"><i className="fas fa-chevron-right fs-sm"></i></p>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home;